#product-category {
  .swiper-viewport {
    border: none;
    border-radius: 0;
    box-shadow: none
  }
  h2{
    font-size:36px;
    line-height:36px;
    letter-spacing:1.2px;
    margin-bottom:45px
  }
  .category-desc {
    padding-bottom: 20px;
  }
  .catalog-top-categories, .catalog-subcategory {
    display:flex;
    //border:1px solid rgba(1,21,43,.232592);
    margin-bottom:70px;
    position: relative;
    z-index: 3;
    flex-wrap: wrap;
    .catalog-subcategory {
      flex-wrap: wrap;
      background-color: var(--white);
    }
    .catalog-subcategories {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      .catalog-subcategory-container {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    .item-container{
      position:relative;
      width:calc(100% / 6);
      flex-shrink:0;
      padding:20px;
      .image{
        width:90%;
        margin:0 auto 30px;
        img{
          object-fit:cover;
          width:100%
        }
      }
      .name{
        font-size:13px;
        line-height:13px;
        text-align:center;
      }
      &.item-active {
        border-bottom: 2px solid var(--red);
      }
      &:after{
        //content:'';
        display:block;
        height:90%;
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        right:0;
        width:1px;
        background-color:rgba(1,21,43,.232592);
      }
      &:nth-child(6n + 6):after {
        content: none;
      }
    }
    &.first-categories {
      .item-container {
        width:calc(100% / 3);
        .item {
          display: block;
          position: relative;
          &:hover {
            opacity: 1;
          }
        }
        .image{
          overflow: hidden;
          img {
            transition: .5s;
          }
          &:hover {
            img {
              transform: scale(1.2);
            }
          }
        }
        .name {
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
          font-size: 20px;
        }
      }
    }
  }
  .filters-group {
    display: flex;
    align-items: center;
    div:last-child {
      margin-left: auto;
    }
  }
  .filters-trigger {
    margin-bottom: 15px;
    border: 1px solid var(--dark-bg);
    cursor: pointer;
  }
  .filters-container {
    position: fixed;
    width: 300px;
    z-index: 10;
    height: 100%;
    right: -300px;
    top: 0;
    bottom: 0;
    transition: .4s;
    &.show {
      right: 0;
    }
    .rnd-t-btn {
      border: 1px solid var(--dark-bg);
    }
    .list-group {
      overflow: auto;
      height: calc(100% - 110px);
    }
  }

  .home-custom-banners, .home-category-banners {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;
    width: 1320px;
    max-width: 100%;
    .item {
      display: block;
      width: calc(50% - 15px);
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: .6s;
        transform: scale(1);
      }
      .text {
        left: 50%;
        top: 90px;
        position: absolute;
        transform: translateX(-50%);
      }
      h3 {
        font-size: 13px;
        letter-spacing: 10px;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--white);
        max-width: 100%;
        width: max-content;
      }
      .arrow {
        margin: 30px auto;
        width: 40px;
        height: 10px;
        border-bottom: 2px solid #fff;
        position: relative;
        transition: .6s;
        &:after {
          content: "";
          position: absolute;
          height: 10px;
          width: 2px;
          left: calc(100% - 5px);
          transform: rotate(135deg);
          bottom: -2px;
          background-color: #fff;
        }
      }
      &:nth-child(2n + 1) {
        margin-right: 15px;
      }
      &:hover {
        opacity: 1;
        img {
          transform: scale(1.15);
        }
        .arrow {
          width: 80px;
        }
      }
    }
  }
  .home-category-banners {
    .item {
      width: calc(100% / 3 - 15px);
      margin-right: 15px;
      .text {
        top: unset;
        bottom: 15px;
      }
      h3 {
        color: var(--dark-text);
      }
      .arrow {
        border-color: var(--dark-text);
        margin: 15px auto 0;
        &:after {
          background-color: var(--dark-text);
        }
      }
      &:nth-child(2) {
        width: calc(100% / 3 * 2 - 15px);
      }
      &:nth-child(2n + 1) {
        margin-right: 15px;
      }
    }
  }
}
@media (max-width: 768px) {
  #product-category {
    .catalog-subcategory, .catalog-top-categories {
      .item-container{
        width: calc(100% / 4);
        &:nth-child(6n + 6):after {
          //content: '';
        }
        &:nth-child(4n + 4):after {
          content: none;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #product-category {
    .catalog-subcategory, .catalog-top-categories {
      .item-container{
        width: calc(100% / 2);
        &:nth-child(6n + 6):after {
          //content: '';
        }
        &:nth-child(4n + 4):after {
          //content: '';
        }
        &:nth-child(2n + 2):after {
          content: none;
        }
      }
      &.first-categories {
        .item-container {
          width: calc(100% / 2);
        }
      }
    }
    .home-category-banners {
      .item {
        width: 100%;
        &:nth-child(2) {
          width: 100%;
          margin-right: 0;
        }
        &:nth-child(2n + 1) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}