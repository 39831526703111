@font-face {
    font-family: 'Muller';
    src: url('MullerUltraLight.eot');
    src: url('MullerUltraLight.eot?#iefix') format('embedded-opentype'),
        url('MullerUltraLight.woff2') format('woff2'),
        url('MullerUltraLight.woff') format('woff'),
        url('MullerUltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerLight.eot');
    src: url('MullerLight.eot?#iefix') format('embedded-opentype'),
        url('MullerLight.woff2') format('woff2'),
        url('MullerLight.woff') format('woff'),
        url('MullerLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('MullerHairlineItalic.eot');
    src: url('MullerHairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerHairlineItalic.woff2') format('woff2'),
        url('MullerHairlineItalic.woff') format('woff'),
        url('MullerHairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerLightItalic.eot');
    src: url('MullerLightItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerLightItalic.woff2') format('woff2'),
        url('MullerLightItalic.woff') format('woff'),
        url('MullerLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerExtraBold.eot');
    src: url('MullerExtraBold.eot?#iefix') format('embedded-opentype'),
        url('MullerExtraBold.woff2') format('woff2'),
        url('MullerExtraBold.woff') format('woff'),
        url('MullerExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerUltraLightItalic.eot');
    src: url('MullerUltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerUltraLightItalic.woff2') format('woff2'),
        url('MullerUltraLightItalic.woff') format('woff'),
        url('MullerUltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerExtraBoldItalic.eot');
    src: url('MullerExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerExtraBoldItalic.woff2') format('woff2'),
        url('MullerExtraBoldItalic.woff') format('woff'),
        url('MullerExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerBlackItalic.eot');
    src: url('MullerBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerBlackItalic.woff2') format('woff2'),
        url('MullerBlackItalic.woff') format('woff'),
        url('MullerBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerBold.eot');
    src: url('MullerBold.eot?#iefix') format('embedded-opentype'),
        url('MullerBold.woff2') format('woff2'),
        url('MullerBold.woff') format('woff'),
        url('MullerBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerMediumItalic.eot');
    src: url('MullerMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerMediumItalic.woff2') format('woff2'),
        url('MullerMediumItalic.woff') format('woff'),
        url('MullerMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerMedium.eot');
    src: url('MullerMedium.eot?#iefix') format('embedded-opentype'),
        url('MullerMedium.woff2') format('woff2'),
        url('MullerMedium.woff') format('woff'),
        url('MullerMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerThinItalic.eot');
    src: url('MullerThinItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerThinItalic.woff2') format('woff2'),
        url('MullerThinItalic.woff') format('woff'),
        url('MullerThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerHeavyItalic.eot');
    src: url('MullerHeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerHeavyItalic.woff2') format('woff2'),
        url('MullerHeavyItalic.woff') format('woff'),
        url('MullerHeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muller Regular';
    src: url('MullerRegularItalic.eot');
    src: url('MullerRegularItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerRegularItalic.woff2') format('woff2'),
        url('MullerRegularItalic.woff') format('woff'),
        url('MullerRegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('MullerHairline.eot');
    src: url('MullerHairline.eot?#iefix') format('embedded-opentype'),
        url('MullerHairline.woff2') format('woff2'),
        url('MullerHairline.woff') format('woff'),
        url('MullerHairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerRegular.eot');
    src: url('MullerRegular.eot?#iefix') format('embedded-opentype'),
        url('MullerRegular.woff2') format('woff2'),
        url('MullerRegular.woff') format('woff'),
        url('MullerRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerHeavy.eot');
    src: url('MullerHeavy.eot?#iefix') format('embedded-opentype'),
        url('MullerHeavy.woff2') format('woff2'),
        url('MullerHeavy.woff') format('woff'),
        url('MullerHeavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerBlack.eot');
    src: url('MullerBlack.eot?#iefix') format('embedded-opentype'),
        url('MullerBlack.woff2') format('woff2'),
        url('MullerBlack.woff') format('woff'),
        url('MullerBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerBoldItalic.eot');
    src: url('MullerBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('MullerBoldItalic.woff2') format('woff2'),
        url('MullerBoldItalic.woff') format('woff'),
        url('MullerBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerThin.eot');
    src: url('MullerThin.eot?#iefix') format('embedded-opentype'),
        url('MullerThin.woff2') format('woff2'),
        url('MullerThin.woff') format('woff'),
        url('MullerThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

