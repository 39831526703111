.section {
  width: 1130px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1440px) {

}
@media (max-width: 1200px) {
  .section {
    width: 992px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 992px) {
  .section {
    width: 768px;
  }
}
@media (max-width: 768px) {
  .section {
    padding-left: 20px;
    padding-right: 20px;
    width: 576px;
  }
}
@media (max-width: 576px) {
  .section {
    width: 100%;
  }
}