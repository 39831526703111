@charset "UTF-8";
@import url(../fonts/Muller/stylesheet.css);
@import url(./../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@import url(./../../node_modules/slick-slider/slick/slick.css);
:root {
  --dark-bg: #101D2A;
  --dark-text: #01152B;
  --grey-text: #9DAFC2;
  --lightgrey-text: #4E6781;
  --red: #D22835;
  --white: #ffffff;
  --grey-border: rgba(131, 160, 191, 0.5);
  --grey-like: #E3E9F0;
  --grey-bg: #F2F2F2;
  --grey-ancient-text: #5D7692;
  --product-bg: #F7F6F4;
  --green: #76BD29;
  --btn-green: #6DB84F;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

b, strong {
  font-weight: bold;
}

body {
  font-family: Muller, sans-serif;
  color: var(--dark-text);
}

body.ov-hidden {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--dark-text);
  transition: .3s;
}

a:hover {
  opacity: .6;
}

.radio img {
  display: none;
}

p {
  color: var(--dark-text);
  font-size: 16px;
  line-height: 19px;
  margin: 10px 0;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 29, 42, 0.2);
  z-index: 9;
}

.overlay.show {
  display: block;
}

#account-register {
  padding-bottom: 100px;
}

#account-login #content a {
  display: inline-block;
  margin-top: 10px;
}

#account-login #column-right {
  display: none;
}

fieldset legend {
  margin-bottom: 16px;
}

#account-account h2 {
  margin: 12px 0 4px;
}

#account-account #content li {
  margin-bottom: 2px;
}

.no-scroll {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.no-scroll::-webkit-scrollbar {
  width: 0px;
  /* For Chrome, Safari, and Opera */
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: Muller, sans-serif;
}

h1 {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--dark-text);
  margin-bottom: 30px;
}

.header-main + *:not(#common-home) {
  padding-top: 150px;
}

.breadcrumb {
  border: none;
  background-color: transparent;
}

.breadcrumb li {
  opacity: 0.5;
  padding: 0;
}

.breadcrumb li:after {
  content: '—';
  display: inline-block;
  position: relative;
  top: unset;
  right: unset;
  width: unset;
  height: unset;
  border: none;
  transform: none;
  margin: 0 10px;
}

.breadcrumb li:last-child:after {
  content: none;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
    line-height: 24px;
  }
}

.rnd-t-btn {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  padding: 17px 30px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
}

.rnd-dark-btn {
  border: 1px solid var(--dark-text);
  background-color: var(--dark-text);
  border-radius: 50px;
  padding: 17px 30px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
  color: var(--white);
  transition: .2s;
}

.rnd-dark-btn svg {
  width: 16px;
  height: 16px;
  fill: var(--white);
  margin-right: 14px;
  vertical-align: middle;
  transition: .2s;
}

.rnd-dark-btn:hover {
  opacity: .9;
  color: var(--grey-text);
}

.rnd-dark-btn:hover svg {
  fill: var(--grey-text);
}

.rnd-green-btn {
  transition: .2s;
  border: 1px solid var(--btn-green);
  background-color: var(--btn-green);
  border-radius: 50px;
  padding: 17px 30px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
  color: var(--white);
}

.rnd-green-btn svg {
  width: 16px;
  height: 16px;
  fill: var(--white);
  margin-right: 14px;
  vertical-align: middle;
  transition: .2s;
}

.rnd-green-btn:hover {
  opacity: .9;
  color: var(--dark-text);
}

.rnd-green-btn:hover svg {
  fill: var(--dark-text);
}

.quantity-controls {
  display: flex;
  align-items: center;
  user-select: none;
}

.quantity-controls input.form-control {
  border: none;
  background: none;
  box-shadow: none;
  width: 45px;
  text-align: center;
}

.quantity-controls .quantity-control {
  cursor: pointer;
  width: 26px;
  height: 26px;
  color: var(--dark-text);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .2s;
  flex-shrink: 0;
}

.quantity-controls .quantity-control:hover {
  background-color: var(--dark-text);
  color: var(--white);
}

.btn-primary {
  background-image: none;
  background-color: var(--btn-green);
  border-color: var(--btn-green);
}

.btn-primary:hover {
  background-color: var(--green);
}

footer {
  background-color: var(--white);
}

.footer-main {
  position: relative;
}

.footer-main a {
  font-size: 15px;
  text-decoration: none;
  color: var(--dark-text);
}

.footer-main a:hover, .footer-main a:focus, .footer-main a:visited {
  color: var(--dark-text);
}

.footer-main .footer-top {
  border-top: 1px solid var(--grey-border);
}

.footer-main .footer-top .footer-top-inner {
  padding-top: 45px;
  padding-bottom: 40px;
  display: flex;
  align-items: flex-start;
}

.footer-main .footer-top .footer-top-inner #logo {
  margin-right: 80px;
}

.footer-main .footer-top .footer-top-inner .nav-info, .footer-main .footer-top .footer-top-inner .nav-menu {
  margin-right: 80px;
}

.footer-main .footer-top .footer-top-inner .nav-info ul, .footer-main .footer-top .footer-top-inner .nav-menu ul {
  display: flex;
  flex-wrap: wrap;
}

.footer-main .footer-top .footer-top-inner .nav-info ul li, .footer-main .footer-top .footer-top-inner .nav-menu ul li {
  white-space: nowrap;
  width: calc(50% - 40px);
  margin-bottom: 1em;
}

.footer-main .footer-top .footer-top-inner .nav-info ul li:nth-child(2n + 1), .footer-main .footer-top .footer-top-inner .nav-menu ul li:nth-child(2n + 1) {
  margin-right: 80px;
}

.footer-main .footer-top .footer-top-inner .nav-info ul li {
  min-width: max-content;
}

.footer-main .footer-top .footer-top-inner .footer-contacts a {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  display: inline-block;
}

.footer-main .footer-top .footer-top-inner #logo, .footer-main .footer-top .footer-top-inner .footer-contacts {
  flex-shrink: 0;
}

.footer-main .footer-bottom {
  border-top: 1px solid var(--grey-border);
}

.footer-main .footer-bottom .footer-bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}

.footer-main .footer-bottom .footer-bottom-inner .copyright p {
  font-size: 13px;
}

.footer-main .footer-bottom .footer-bottom-inner .documents a, .footer-main .footer-bottom .footer-bottom-inner .license a {
  font-size: 11px;
  text-transform: uppercase;
}

.footer-main .footer-bottom .footer-bottom-inner .dev {
  font-size: 13px;
}

.footer-main .footer-bottom .footer-bottom-inner .dev span {
  color: var(--red);
}

@media (max-width: 1200px) {
  .footer-main .footer-top .footer-top-inner {
    flex-wrap: wrap;
  }
  .footer-main .footer-top .footer-top-inner .nav-info, .footer-main .footer-top .footer-top-inner .footer-contacts {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .footer-main .footer-top .footer-top-inner,
  .footer-main .footer-top .footer-top-inner .nav-info ul,
  .footer-main .footer-top .footer-top-inner .nav-menu ul,
  .footer-main .footer-bottom .footer-bottom-inner {
    display: block;
  }
  .footer-main .footer-top .footer-top-inner li,
  .footer-main .footer-top .footer-top-inner .nav-info ul li,
  .footer-main .footer-top .footer-top-inner .nav-menu ul li,
  .footer-main .footer-bottom .footer-bottom-inner li {
    width: unset;
  }
  .footer-main .footer-bottom .footer-bottom-inner > div {
    margin-top: 12px;
  }
}

.alert {
  position: fixed;
  width: 99%;
  top: 0;
  left: 0;
  z-index: 6;
}

.alert-dismissible .close {
  right: 0;
}

#cart {
  display: none;
  width: 950px;
  max-width: 100%;
  padding: 30px 44px;
}

#cart h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-text);
}

#cart h2:after {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  margin: 12px 0 30px;
  background-color: #E8ECEF;
}

#cart .cart-footer:before {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  margin: 12px 0 45px;
  background-color: #E8ECEF;
}

#cart .dropdown-menu {
  display: block;
  float: none;
  position: relative;
}

#cart .pop-cart-list {
  display: block;
  float: none;
  position: relative;
  border: none;
  box-shadow: none;
  background: transparent;
}

#cart .product-name {
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.566667px;
  color: var(--dark-text);
}

#cart .product-total {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.533333px;
  color: var(--dark-text);
}

#cart .table {
  border: none;
}

#cart .table > tbody > tr > td {
  border: 0;
}

#cart .table-striped > tbody > tr:nth-of-type(2n+1) {
  background: transparent;
}

#cart .rnd-green-btn {
  margin-left: 24px;
}

.header-main {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  color: var(--dark-text);
  transition: .3s;
}

.header-main a {
  color: var(--dark-text);
  transition: .3s;
}

.header-main.home-top {
  background-color: transparent;
  color: var(--white);
}

.header-main.home-top a {
  color: var(--white);
}

.header-main.home-top .header-top .menu-trigger:before, .header-main.home-top .header-top .menu-trigger:after {
  background-color: var(--white);
}

.header-main.home-top .header-top .header-right .header-icons svg {
  fill: var(--white);
}

.header-main.page-scrolled {
  background-color: var(--white);
}

.header-main.page-scrolled .header-top {
  height: 90px;
  padding: 20px 85px 20px 60px;
}

.header-main .header-top {
  transition: .2s;
  position: relative;
  z-index: 6;
  height: 150px;
  padding: 50px 85px 50px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main .header-top .header-left {
  display: flex;
  align-items: center;
}

.header-main .header-top .header-left #logo {
  width: 112px;
  height: 51px;
  margin-right: 3.625vw;
}

.header-main .header-top .header-left .header-nav {
  display: flex;
  align-items: center;
}

.header-main .header-top .header-left .header-nav li {
  margin-left: 36px;
}

.header-main .header-top .header-left .header-nav li:first-child {
  margin-left: 0;
}

.header-main .header-top .menu-trigger {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header-main .header-top .menu-trigger:before, .header-main .header-top .menu-trigger:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--dark-text);
  transition: .3s;
  left: 0;
  top: 0;
  transform-origin: top left;
}

.header-main .header-top .menu-trigger:after {
  transform-origin: bottom left;
  top: 8px;
  width: 14px;
  height: 1px;
}

.header-main .header-top .menu-trigger.open:before {
  transform: rotate(45deg);
  width: 36px;
}

.header-main .header-top .menu-trigger.open:after {
  top: 100%;
  transform: rotate(-45deg);
  width: 36px;
  height: 2px;
}

.header-main .header-top .header-right {
  display: flex;
  align-items: center;
}

.header-main .header-top .header-right .header-icons {
  margin-left: 4.875vw;
  display: flex;
  align-items: center;
}

.header-main .header-top .header-right .header-icons li {
  margin-left: 27px;
}

.header-main .header-top .header-right .header-icons svg {
  transition: .3s fill;
  width: 100%;
  height: auto;
  max-width: 16px;
  max-height: 16px;
  object-fit: cover;
  fill: var(--dark-text);
}

.header-main .header-top .header-right .header-icons .cart-icon {
  position: relative;
}

.header-main .header-top .header-right .header-icons .cart-icon .cart-total {
  position: absolute;
  left: 50%;
  bottom: 100%;
  white-space: nowrap;
}

.header-main .header-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--dark-bg);
}

.header-main .header-menu .menu-container {
  padding-top: 190px;
}

.header-main .header-menu .menu-container .menu-top, .header-main .header-menu .menu-container .menu-bottom {
  margin: 0 auto;
  width: 1464px;
  max-width: 100%;
  padding: 0 20px;
}

.header-main .header-menu .menu-container .menu-top {
  display: flex;
  justify-content: space-between;
}

.header-main .header-menu .menu-container .menu-top .nav-menu, .header-main .header-menu .menu-container .menu-top .nav-info {
  width: 45%;
}

.header-main .header-menu .menu-container .menu-top .nav-menu ul, .header-main .header-menu .menu-container .menu-top .nav-info ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header-main .header-menu .menu-container .menu-top .nav-menu ul li, .header-main .header-menu .menu-container .menu-top .nav-info ul li {
  width: 45%;
  margin-bottom: 2em;
  white-space: nowrap;
}

.header-main .header-menu .menu-container .menu-top .nav-menu a, .header-main .header-menu .menu-container .menu-top .nav-info a {
  font-size: 24px;
  line-height: 28px;
}

.header-main.open {
  color: var(--white);
}

.header-main.open a {
  color: var(--white);
}

.header-main.open .header-top .menu-trigger:before, .header-main.open .header-top .menu-trigger:after {
  background-color: var(--white);
}

.header-main.open .header-top .header-right .header-icons svg {
  fill: var(--white);
}

.header-main .search-container {
  position: relative;
}

.header-main .search-container #search {
  margin: 0;
  position: absolute;
  bottom: 0;
  right: calc(100% + 8px);
  width: 300px;
  display: none;
  overflow: hidden;
  max-width: calc(100vw - 150px);
}

.header-main .search-container #search input {
  background-color: var(--white);
  color: var(--dark-text);
  border-radius: 8px;
  width: 100%;
  padding: 4px 6px;
}

.header-main .search-container #search .input-group-btn {
  display: none;
}

.header-main .search-container #search.active {
  width: 300px;
}

.header-main .search-container #search + div {
  position: absolute;
  top: 40px;
  right: calc(100% + 8px);
  width: 300px;
  max-width: calc(100vw - 150px);
}

@media (max-width: 1024px) {
  .header-main .header-menu .menu-container .menu-top .nav-info ul li, .header-main .header-menu .menu-container .menu-top .nav-menu ul li {
    white-space: unset;
  }
}

@media (max-width: 768px) {
  .header-main .header-top {
    height: 100px;
    padding: 20px;
  }
  .header-main .header-top .header-right .header-icons .cart-icon .cart-total {
    left: unset;
    right: 0;
  }
  .header-main.page-scrolled .header-top {
    padding: 20px;
  }
  .header-main .header-menu .menu-container {
    padding-top: 100px;
    height: 100%;
    overflow: auto;
  }
  .header-main .header-menu .menu-container .menu-top {
    flex-direction: column;
    align-items: center;
  }
  .header-main .header-menu .menu-container .menu-top .nav-menu, .header-main .header-menu .menu-container .menu-top .nav-info {
    width: 100%;
  }
  .header-main .header-menu .menu-container .menu-top .nav-menu ul, .header-main .header-menu .menu-container .menu-top .nav-info ul {
    flex-direction: column;
    align-items: center;
  }
  .header-main .header-menu .menu-container .menu-top .nav-menu ul li, .header-main .header-menu .menu-container .menu-top .nav-info ul li {
    width: 100%;
    text-align: center;
  }
  .header-main .header-menu .menu-container .menu-top .nav-menu {
    border-bottom: 1px solid var(--lightgrey-text);
  }
  .header-main .header-menu .menu-container .menu-top .nav-info {
    padding-top: 15px;
  }
  .header-main.open .header-top {
    background-color: var(--dark-bg);
  }
  .header-main .header-top .header-left .header-nav,
  .header-main .header-top .header-right > a {
    display: none;
  }
}

@media (max-width: 576px) {
  #cart .pop-cart-list .table tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  #cart .cart-footer a {
    display: block;
    text-align: center;
  }
  #cart .cart-footer a:last-child {
    margin: 16px 0 0;
  }
}

.latest-products-container {
  width: 1520px;
  max-width: 100%;
  margin: 0 auto;
}

.latest-products-container .section-header {
  display: flex;
  margin-bottom: 100px;
}

.latest-products-container .section-header .header {
  display: flex;
  align-items: flex-end;
}

.latest-products-container .section-header .header h3 {
  font-size: 53px;
  line-height: 53px;
  letter-spacing: 1.76667px;
  margin-bottom: -10px;
}

.latest-products-container .section-header .header a {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.22px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: var(--red);
}

.latest-products-container .section-header .slider-arrows {
  margin-left: auto;
  display: flex;
}

.latest-products-container .section-header .slider-arrows .slick-arrow {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(170, 170, 170, 0.5);
  position: relative;
  transition: .3s;
  cursor: pointer;
}

.latest-products-container .section-header .slider-arrows .slick-arrow svg {
  width: 8px;
  height: 16px;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 8px);
  fill: transparent;
  stroke: var(--dark-text);
  stroke-width: 2px;
  transition: .3s;
}

.latest-products-container .section-header .slider-arrows .slick-arrow.slick-next {
  margin-left: 20px;
  transform: rotateY(180deg);
}

.latest-products-container .section-header .slider-arrows .slick-arrow:hover {
  background-color: var(--red);
}

.latest-products-container .section-header .slider-arrows .slick-arrow:hover svg {
  stroke: var(--white);
}

.latest-products-container .latest-products .product-item {
  padding: 0 23px;
  margin-bottom: 100px;
}

.latest-products-container .latest-products .product-item:hover {
  margin-bottom: 0;
}

.latest-products-container .slider-numbers {
  margin-top: 100px;
  text-align: center;
}

.latest-products-container .slider-numbers span {
  vertical-align: baseline;
  font-weight: 100;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
  color: var(--dark-text);
  opacity: .5;
}

.latest-products-container .slider-numbers span.current {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.62667px;
  opacity: 1;
  font-weight: 500;
}

.latest-products-container .slider-numbers span.all {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

@media (max-width: 576px) {
  .latest-products-container .section-header {
    display: block;
    margin-bottom: 0;
  }
  .latest-products-container .section-header .slider-arrows {
    margin: 20px 0 0;
    justify-content: flex-end;
  }
}

.section {
  width: 1130px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .section {
    width: 992px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 992px) {
  .section {
    width: 768px;
  }
}

@media (max-width: 768px) {
  .section {
    padding-left: 20px;
    padding-right: 20px;
    width: 576px;
  }
}

@media (max-width: 576px) {
  .section {
    width: 100%;
  }
}

.product-item {
  position: relative;
  padding: 20px;
  transition: .35s;
}

.product-item .product-thumb {
  border: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-item .product-thumb .img-additional {
  display: none;
}

.product-item .product-thumb .caption {
  padding: 43px 0 0;
  flex-grow: 1;
}

.product-item .product-thumb .caption h4 a {
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.566667px;
  font-style: normal;
  font-weight: normal;
}

.product-item .product-thumb .caption > p {
  margin: 10px 0 14px;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.433333px;
  color: var(--grey-text);
}

.product-item .product-thumb .caption .sku {
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.497px;
  color: var(--grey-text);
}

.product-item .product-thumb .caption .product-item-options {
  padding-top: 12px;
}

.product-item .product-thumb .caption .product-item-options .nomat-price {
  padding-bottom: 12px;
}

.product-item .product-thumb .caption .product-item-options .nomat-price span {
  font-weight: bold;
}

.product-item .product-thumb .caption .product-item-options .form-select {
  width: 50%;
  float: left;
}

.product-item .product-thumb .caption .product-item-options:after {
  display: table;
  content: '';
  clear: both;
}

.product-item .button-group {
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: space-between;
}

.product-item .button-group .price {
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.566667px;
}

.product-item .button-group .cart-add-btn {
  width: 48px;
  height: 48px;
  background-color: var(--dark-text);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.product-item .button-group .cart-add-btn svg {
  width: 16px;
  height: 16px;
  fill: var(--white);
}

.product-item .button-group .cart-add-btn:hover {
  background-color: var(--red);
}

.product-item .button-group .wishlist-add-btn {
  opacity: 0;
  transition: .3s;
  position: absolute;
  top: 18px;
  right: 23px;
  width: auto;
  border: none;
  height: auto;
  background-color: transparent;
}

.product-item .button-group .wishlist-add-btn:hover {
  background-color: transparent;
}

.product-item .button-group .wishlist-add-btn:hover svg {
  fill: var(--grey-text);
}

.product-item .button-group .wishlist-add-btn svg {
  width: 18px;
  height: 16px;
  fill: var(--grey-like);
}

.product-item .radio label {
  width: 100%;
}

.product-item .radio input {
  top: -3px;
}

.product-item .radio .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-item .radio .option-price {
  font-weight: bold;
}

.product-item:hover {
  box-shadow: 0px 13px 33px rgba(0, 0, 0, 0.0805593);
}

.product-item:hover .button-group .wishlist-add-btn {
  opacity: 1;
}

.catalog-items {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px;
}

.catalog-items .product-item {
  width: calc(25% - 60px);
  margin: 33px 30px;
}

@media (max-width: 1200px) {
  .catalog-items .product-item {
    width: calc(100% / 3 - 30px * 2);
  }
}

@media (max-width: 992px) {
  .catalog-items {
    margin-left: 0;
    margin-right: 0;
  }
  .catalog-items .product-item {
    width: calc(100% / 2 - 30px * 2);
  }
}

@media (max-width: 768px) {
  .catalog-items .product-item {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .catalog-items .product-item {
    width: 100%;
  }
}

.ancient-container {
  text-align: center;
  width: 780px;
  max-width: 100%;
  margin: 100px auto;
}

.ancient-container .ancient-image {
  text-align: center;
  margin: 0 auto 47px;
  height: 35px;
  width: 35px;
}

.ancient-container h3 {
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 1.02px;
  text-transform: uppercase;
  margin-bottom: 23px;
}

.ancient-container p {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.533333px;
  color: var(--grey-ancient-text);
}

#common-home .home-head {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 100px;
}

#common-home .home-head .slider-arrows {
  position: absolute;
  top: calc(50% - 67px);
  right: 90px;
  z-index: 1;
}

#common-home .home-head .slider-arrows .slick-arrow {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  transform-origin: center center;
  background-color: transparent;
  box-shadow: 0 4px 12px rgba(170, 170, 170, 0.5);
  position: relative;
  transition: .3s;
  cursor: pointer;
  border: 1px solid var(--white);
}

#common-home .home-head .slider-arrows .slick-arrow svg {
  width: 8px;
  height: 16px;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 8px);
  fill: transparent;
  stroke: var(--white);
  stroke-width: 2px;
  transition: .3s;
}

#common-home .home-head .slider-arrows .slick-arrow.slick-next {
  margin-top: 20px;
  transform: rotateY(180deg);
}

#common-home .home-head .slider-arrows .slick-arrow:hover {
  background-color: var(--red);
  border: 1px solid var(--red);
}

#common-home .home-head .slider-arrows .slick-arrow:hover svg {
  stroke: var(--white);
}

#common-home .home-head .home-head-slider {
  height: 100%;
  width: 100%;
}

#common-home .home-head .home-head-slider .item {
  position: relative;
  width: 100%;
  height: 100vh;
}

#common-home .home-head .home-head-slider .item .text {
  margin-left: 14vw;
  width: 875px;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  position: relative;
}

#common-home .home-head .home-head-slider .item .text .preheader {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin-bottom: 37px;
  color: var(--white);
}

#common-home .home-head .home-head-slider .item .text h2 {
  font-size: 89px;
  line-height: 89px;
  letter-spacing: 4.1px;
  margin-bottom: 64px;
  color: var(--white);
  margin-left: -6px;
}

#common-home .home-head .home-head-slider .item .text .description, #common-home .home-head .home-head-slider .item .text p, #common-home .home-head .home-head-slider .item .text .desc * {
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 1.2375px;
  max-width: 424px;
  margin-bottom: 96px;
  color: var(--white);
}

#common-home .home-head .home-head-slider .item .text .rnd-t-btn {
  color: var(--white);
  width: max-content;
}

#common-home .home-head .home-head-slider .bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#common-home .home-head .home-head-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes scrollDownAnimation {
  0% {
    width: 0;
    right: 0;
  }
  50% {
    width: 100%;
    right: 0;
  }
  100% {
    right: 100%;
    width: 0;
  }
}

#common-home .home-head .home-head-scroll {
  position: absolute;
  bottom: 0;
  left: unset;
  right: 100px;
  transform-origin: left bottom;
  transform: rotate(-90deg);
  width: 150px;
  text-align: right;
  color: var(--white);
}

#common-home .home-head .home-head-scroll:after {
  content: '';
  display: block;
  bottom: 0;
  height: 1px;
  background-color: var(--white);
  position: absolute;
  right: 0;
  width: 100%;
  animation: 4s linear infinite scrollDownAnimation;
}

#common-home .home-middle-container {
  position: relative;
}

#common-home .home-middle-container .slider-arrows {
  position: absolute;
  top: calc(50% - 67px);
  right: 90px;
  z-index: 1;
}

#common-home .home-middle-container .slider-arrows .slick-arrow {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  transform-origin: center center;
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(170, 170, 170, 0.5);
  position: relative;
  transition: .3s;
  cursor: pointer;
}

#common-home .home-middle-container .slider-arrows .slick-arrow svg {
  width: 8px;
  height: 16px;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 8px);
  fill: transparent;
  stroke: var(--dark-text);
  stroke-width: 2px;
  transition: .3s;
}

#common-home .home-middle-container .slider-arrows .slick-arrow.slick-next {
  margin-top: 20px;
  transform: rotateY(180deg);
}

#common-home .home-middle-container .slider-arrows .slick-arrow:hover {
  background-color: var(--red);
}

#common-home .home-middle-container .slider-arrows .slick-arrow:hover svg {
  stroke: var(--white);
}

#common-home .home-middle-container .item {
  position: relative;
}

#common-home .home-middle-container .item .text {
  background-color: var(--white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 561px;
  max-width: 60%;
  z-index: 2;
  padding: 50px 90px 70px 70px;
}

#common-home .home-middle-container .item .text .preheader {
  font-size: 14px;
  line-height: 27px;
  letter-spacing: .466667px;
  text-transform: uppercase;
  color: var(--grey-ancient-text);
  margin-bottom: 55px;
}

#common-home .home-middle-container .item .text h2 {
  font-weight: 400;
  font-size: 58px;
  line-height: 27px;
  letter-spacing: 3.48px;
  color: var(--dark-text);
  margin-bottom: 32px;
}

#common-home .home-middle-container .item .text .description, #common-home .home-middle-container .item .text p {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: .533333px;
  color: var(--grey-ancient-text);
}

#common-home .home-middle-container .item .text .description:after, #common-home .home-middle-container .item .text p:after {
  display: block;
  content: '';
  width: 52px;
  height: 3px;
  background-color: var(--red);
  margin: 37px 0 55px;
}

#common-home .home-middle-container .item .image {
  margin-left: auto;
  width: 60%;
  object-fit: cover;
  z-index: 0;
}

#common-home .home-middle-container .item .image img {
  width: 100%;
}

#common-home .home-middle-container .slider-numbers {
  margin-top: 50px;
  text-align: center;
}

#common-home .home-middle-container .slider-numbers span {
  vertical-align: baseline;
  font-weight: 100;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
  color: var(--dark-text);
  opacity: .5;
}

#common-home .home-middle-container .slider-numbers span.current {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.62667px;
  opacity: 1;
  font-weight: 500;
}

#common-home .home-middle-container .slider-numbers span.all {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

#common-home .home-categories-container {
  width: 1400px;
  max-width: 100%;
  margin: 100px auto;
}

#common-home .home-categories-container .item {
  position: relative;
}

#common-home .home-categories-container .item .text {
  position: absolute;
  z-index: 1;
  width: 460px;
}

#common-home .home-categories-container .item .text .preheader {
  font-size: 14px;
  line-height: 27px;
  letter-spacing: .466667px;
  text-transform: uppercase;
  color: var(--grey-ancient-text);
  margin-bottom: 55px;
}

#common-home .home-categories-container .item .text h2 {
  font-weight: 400;
  font-size: 58px;
  line-height: 58px;
  letter-spacing: 3.48px;
  color: var(--dark-text);
  margin-bottom: 32px;
}

#common-home .home-categories-container .item .text .description {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: .533333px;
  color: var(--grey-ancient-text);
}

#common-home .home-categories-container .item .image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

#common-home .home-categories-container .item.item-right .image {
  margin-left: auto;
}

#common-home .home-categories-container .item:first-child .image {
  width: 513px;
}

#common-home .home-categories-container .item:first-child .text {
  top: 80px;
  left: 420px;
}

#common-home .home-categories-container .item:nth-child(2) .image {
  width: 486px;
}

#common-home .home-categories-container .item:nth-child(2) .text {
  bottom: 60px;
  right: 320px;
}

#common-home .home-categories-container .item:nth-child(3) .image {
  width: 424px;
}

#common-home .home-categories-container .item:nth-child(3) .text {
  left: 50px;
  bottom: 0;
  transform: translateY(50%);
}

#common-home .home-categories-container .item:nth-child(4) .image {
  width: 553px;
}

#common-home .home-categories-container .item:nth-child(4) .text {
  bottom: 60px;
  right: 360px;
}

#common-home .home-categories-container .item:nth-child(5) .image {
  width: 445px;
}

#common-home .home-categories-container .item:nth-child(5) .text {
  top: 120px;
  left: 420px;
}

#common-home .home-custom-banners, #common-home .home-category-banners {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;
  width: 1320px;
  max-width: 100%;
}

#common-home .home-custom-banners .item, #common-home .home-category-banners .item {
  display: block;
  width: calc(50% - 15px);
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

#common-home .home-custom-banners .item img, #common-home .home-category-banners .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: .6s;
  transform: scale(1);
}

#common-home .home-custom-banners .item .text, #common-home .home-category-banners .item .text {
  left: 50%;
  top: 90px;
  position: absolute;
  transform: translateX(-50%);
}

#common-home .home-custom-banners .item h3, #common-home .home-category-banners .item h3 {
  font-size: 13px;
  letter-spacing: 10px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--white);
  max-width: 100%;
  width: max-content;
}

#common-home .home-custom-banners .item .arrow, #common-home .home-category-banners .item .arrow {
  margin: 30px auto;
  width: 40px;
  height: 10px;
  border-bottom: 2px solid #fff;
  position: relative;
  transition: .6s;
}

#common-home .home-custom-banners .item .arrow:after, #common-home .home-category-banners .item .arrow:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 2px;
  left: calc(100% - 5px);
  transform: rotate(135deg);
  bottom: -2px;
  background-color: #fff;
}

#common-home .home-custom-banners .item:nth-child(2n + 1), #common-home .home-category-banners .item:nth-child(2n + 1) {
  margin-right: 15px;
}

#common-home .home-custom-banners .item:hover, #common-home .home-category-banners .item:hover {
  opacity: 1;
}

#common-home .home-custom-banners .item:hover img, #common-home .home-category-banners .item:hover img {
  transform: scale(1.15);
}

#common-home .home-custom-banners .item:hover .arrow, #common-home .home-category-banners .item:hover .arrow {
  width: 80px;
}

#common-home .home-category-banners .item {
  width: calc(100% / 3 - 15px);
  margin-right: 15px;
}

#common-home .home-category-banners .item .text {
  top: unset;
  bottom: 15px;
}

#common-home .home-category-banners .item h3 {
  color: var(--dark-text);
}

#common-home .home-category-banners .item .arrow {
  border-color: var(--dark-text);
  margin: 15px auto 0;
}

#common-home .home-category-banners .item .arrow:after {
  background-color: var(--dark-text);
}

#common-home .home-category-banners .item:nth-child(2) {
  width: calc(100% / 3 * 2 - 15px);
}

#common-home .home-category-banners .item:nth-child(2n + 1) {
  margin-right: 15px;
}

@media (max-width: 1200px) {
  #common-home .home-head .home-head-slider .item .text {
    margin-left: 6vw;
    width: calc(100% - 6vw);
  }
  #common-home .home-head .home-head-slider .item .text h2 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

@media (max-width: 992px) {
  #common-home .home-categories-container {
    padding: 30px;
  }
  #common-home .home-categories-container .item:nth-child(n) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  #common-home .home-categories-container .item:nth-child(n) .text {
    position: static;
    order: 1;
    transform: unset;
    width: unset;
  }
  #common-home .home-categories-container .item:nth-child(n) .text .preheader {
    margin-bottom: 10px;
  }
  #common-home .home-categories-container .item:nth-child(n) .text h2 {
    margin-bottom: 16px;
  }
  #common-home .home-categories-container .item:nth-child(n) .image {
    order: 0;
    margin-left: 0;
    max-width: 100%;
    width: 350px;
  }
}

@media (max-width: 768px) {
  #common-home .home-categories-container {
    padding: 20px;
  }
  .latest-products-container .section-header .header {
    display: block;
  }
  .latest-products-container .section-header .header h3 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  #common-home .home-head .home-head-slider .item .text {
    width: calc(100% - 6vw - 67px);
  }
  #common-home .home-head .slider-arrows {
    right: 10px;
  }
  #common-home .home-head .home-head-scroll {
    right: -100px;
  }
  #common-home .latest-products-container {
    padding: 0 20px;
  }
  #common-home .ancient-container {
    padding: 10px 20px;
  }
  #common-home .home-middle-container .slider-arrows {
    top: 50%;
    right: 20px;
    z-index: 1;
    transform: translateY(-50%);
  }
  #common-home .home-middle-container .item .text {
    position: static;
    max-width: 100%;
    transform: unset;
    padding: 0 0 30px 0;
  }
  #common-home .home-middle-container .item .image {
    width: 100%;
  }
  #common-home .home-custom-banners .item {
    width: 100%;
    margin: 0 0 20px 0;
  }
  #common-home .home-custom-banners .item:nth-child(2n + 1) {
    margin-right: 0;
  }
  #common-home .home-category-banners .item {
    width: 100%;
  }
  #common-home .home-category-banners .item:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }
  #common-home .home-category-banners .item:nth-child(2n + 1) {
    width: 100%;
    margin-right: 0;
  }
}

#checkout-cart {
  padding-top: 180px;
  padding-bottom: 150px;
}

#checkout-cart:before {
  content: '';
  background-color: var(--grey-bg);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

#checkout-cart .cart-content {
  display: flex;
}

#checkout-cart .cart-container, #checkout-cart .totals-container {
  background-color: var(--white);
}

#checkout-cart .cart-container {
  padding: 25px 30px;
  height: max-content;
}

#checkout-cart .cart-container input {
  margin: 0;
}

#checkout-cart .cart-container .cart-row {
  display: flex;
  align-items: center;
}

#checkout-cart .cart-container .cart-row .ctm-checkbox {
  width: 14px;
  height: 14px;
  margin-right: 21px;
}

#checkout-cart .cart-container .cart-row .product-image {
  width: 95px;
  margin-right: 32px;
}

#checkout-cart .cart-container .cart-row .product-name {
  width: 170px;
  margin-right: 82px;
}

#checkout-cart .cart-container .cart-row .product-price {
  width: 100px;
  text-align: right;
  margin-right: 50px;
}

#checkout-cart .cart-container .cart-row .product-quantity {
  width: 100px;
}

#checkout-cart .cart-container .cart-container-header {
  padding-bottom: 22px;
  border-bottom: 1px solid var(--grey-border);
}

#checkout-cart .cart-container .cart-container-header .product-image .check-all {
  font-size: 13px;
  line-height: 13px;
  color: var(--dark-text);
  opacity: 0.54;
}

#checkout-cart .cart-container .cart-container-header .product-name .delete-selected {
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.433333px;
  color: var(--red);
}

#checkout-cart .cart-container .cart-container-body .cart-row {
  margin-top: 26px;
}

#checkout-cart .cart-container .cart-container-body .cart-row .product-image {
  border-radius: 2px;
  overflow: hidden;
  width: 95px;
  height: 95px;
  background-color: var(--product-bg);
}

#checkout-cart .cart-container .cart-container-body .cart-row .product-image img {
  border: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#checkout-cart .cart-container .cart-container-body .cart-row .product-name a {
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.566667px;
  color: var(--dark-text);
}

#checkout-cart .cart-container .cart-container-body .cart-row .product-name small {
  font-size: 14px;
  line-height: 15px;
  color: var(--grey-text);
}

#checkout-cart .cart-container .cart-container-body .cart-row .product-name .cart-item-remove {
  border: 0;
  background-color: transparent;
  display: block;
  margin-top: 22px;
  color: var(--red);
  padding-left: 0;
}

#checkout-cart .totals-container {
  padding: 15px 30px;
  margin-left: auto;
  height: max-content;
  position: sticky;
  top: 180px;
}

#checkout-cart .totals-container.in-progress::before {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #808080a3;
  position: absolute;
}

#checkout-cart .totals-container h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 46px;
  text-transform: uppercase;
  color: var(--dark-text);
}

#checkout-cart .totals-container .totals:before, #checkout-cart .totals-container .totals:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--grey-border);
  margin: 30px 0;
}

#checkout-cart .totals-container .total {
  display: flex;
  align-items: flex-start;
  color: var(--dark-text);
}

#checkout-cart .totals-container .total > div {
  width: 50%;
  margin-bottom: 16px;
  font-size: 14px;
}

#checkout-cart .totals-container .total > div.total-value {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

#checkout-cart .totals-container .total.total-total > div {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 0;
}

#checkout-cart .totals-container .total.total-total > div.total-value {
  font-size: 21px;
  line-height: 21px;
}

#checkout-cart .totals-container .cart-submit {
  display: block;
  color: var(--white);
  width: 100%;
  padding: 18px;
  text-align: center;
  border-radius: 3px;
  background-color: var(--btn-green);
}

#checkout-cart .totals-container .cart-submit:hover {
  background-color: var(--green);
}

#checkout-cart .totals-container .cart-submit-desc {
  margin-top: 30px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.433333px;
  color: var(--grey-text);
}

@media (max-width: 992px) {
  #checkout-cart .cart-content {
    display: block;
  }
  #checkout-cart .totals-container {
    position: relative;
    top: 0;
  }
}

@media (max-width: 576px) {
  #checkout-cart .cart-container .cart-row {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  #checkout-cart .cart-container .cart-row .product-image {
    margin-right: auto;
  }
  #checkout-cart .cart-container .cart-row .product-name {
    width: 100%;
  }
  #checkout-cart .cart-container .cart-row .product-name .cart-item-remove {
    margin: 10px 0;
  }
  #checkout-cart .cart-container .cart-container-header {
    flex-wrap: nowrap;
    align-items: center;
  }
  #checkout-cart .cart-container .cart-container-header .product-image {
    margin-right: 32px;
  }
  #checkout-cart .cart-container .cart-container-header .product-name {
    width: unset;
  }
}

#product-category .swiper-viewport {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

#product-category h2 {
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 1.2px;
  margin-bottom: 45px;
}

#product-category .category-desc {
  padding-bottom: 20px;
}

#product-category .catalog-top-categories, #product-category .catalog-subcategory {
  display: flex;
  margin-bottom: 70px;
  position: relative;
  z-index: 3;
  flex-wrap: wrap;
}

#product-category .catalog-top-categories .catalog-subcategory, #product-category .catalog-subcategory .catalog-subcategory {
  flex-wrap: wrap;
  background-color: var(--white);
}

#product-category .catalog-top-categories .catalog-subcategories, #product-category .catalog-subcategory .catalog-subcategories {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

#product-category .catalog-top-categories .catalog-subcategories .catalog-subcategory-container, #product-category .catalog-subcategory .catalog-subcategories .catalog-subcategory-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#product-category .catalog-top-categories .item-container, #product-category .catalog-subcategory .item-container {
  position: relative;
  width: calc(100% / 6);
  flex-shrink: 0;
  padding: 20px;
}

#product-category .catalog-top-categories .item-container .image, #product-category .catalog-subcategory .item-container .image {
  width: 90%;
  margin: 0 auto 30px;
}

#product-category .catalog-top-categories .item-container .image img, #product-category .catalog-subcategory .item-container .image img {
  object-fit: cover;
  width: 100%;
}

#product-category .catalog-top-categories .item-container .name, #product-category .catalog-subcategory .item-container .name {
  font-size: 13px;
  line-height: 13px;
  text-align: center;
}

#product-category .catalog-top-categories .item-container.item-active, #product-category .catalog-subcategory .item-container.item-active {
  border-bottom: 2px solid var(--red);
}

#product-category .catalog-top-categories .item-container:after, #product-category .catalog-subcategory .item-container:after {
  display: block;
  height: 90%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  background-color: rgba(1, 21, 43, 0.232592);
}

#product-category .catalog-top-categories .item-container:nth-child(6n + 6):after, #product-category .catalog-subcategory .item-container:nth-child(6n + 6):after {
  content: none;
}

#product-category .catalog-top-categories.first-categories .item-container, #product-category .catalog-subcategory.first-categories .item-container {
  width: calc(100% / 3);
}

#product-category .catalog-top-categories.first-categories .item-container .item, #product-category .catalog-subcategory.first-categories .item-container .item {
  display: block;
  position: relative;
}

#product-category .catalog-top-categories.first-categories .item-container .item:hover, #product-category .catalog-subcategory.first-categories .item-container .item:hover {
  opacity: 1;
}

#product-category .catalog-top-categories.first-categories .item-container .image, #product-category .catalog-subcategory.first-categories .item-container .image {
  overflow: hidden;
}

#product-category .catalog-top-categories.first-categories .item-container .image img, #product-category .catalog-subcategory.first-categories .item-container .image img {
  transition: .5s;
}

#product-category .catalog-top-categories.first-categories .item-container .image:hover img, #product-category .catalog-subcategory.first-categories .item-container .image:hover img {
  transform: scale(1.2);
}

#product-category .catalog-top-categories.first-categories .item-container .name, #product-category .catalog-subcategory.first-categories .item-container .name {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  font-size: 20px;
}

#product-category .filters-group {
  display: flex;
  align-items: center;
}

#product-category .filters-group div:last-child {
  margin-left: auto;
}

#product-category .filters-trigger {
  margin-bottom: 15px;
  border: 1px solid var(--dark-bg);
  cursor: pointer;
}

#product-category .filters-container {
  position: fixed;
  width: 300px;
  z-index: 10;
  height: 100%;
  right: -300px;
  top: 0;
  bottom: 0;
  transition: .4s;
}

#product-category .filters-container.show {
  right: 0;
}

#product-category .filters-container .rnd-t-btn {
  border: 1px solid var(--dark-bg);
}

#product-category .filters-container .list-group {
  overflow: auto;
  height: calc(100% - 110px);
}

#product-category .home-custom-banners, #product-category .home-category-banners {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;
  width: 1320px;
  max-width: 100%;
}

#product-category .home-custom-banners .item, #product-category .home-category-banners .item {
  display: block;
  width: calc(50% - 15px);
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

#product-category .home-custom-banners .item img, #product-category .home-category-banners .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: .6s;
  transform: scale(1);
}

#product-category .home-custom-banners .item .text, #product-category .home-category-banners .item .text {
  left: 50%;
  top: 90px;
  position: absolute;
  transform: translateX(-50%);
}

#product-category .home-custom-banners .item h3, #product-category .home-category-banners .item h3 {
  font-size: 13px;
  letter-spacing: 10px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--white);
  max-width: 100%;
  width: max-content;
}

#product-category .home-custom-banners .item .arrow, #product-category .home-category-banners .item .arrow {
  margin: 30px auto;
  width: 40px;
  height: 10px;
  border-bottom: 2px solid #fff;
  position: relative;
  transition: .6s;
}

#product-category .home-custom-banners .item .arrow:after, #product-category .home-category-banners .item .arrow:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 2px;
  left: calc(100% - 5px);
  transform: rotate(135deg);
  bottom: -2px;
  background-color: #fff;
}

#product-category .home-custom-banners .item:nth-child(2n + 1), #product-category .home-category-banners .item:nth-child(2n + 1) {
  margin-right: 15px;
}

#product-category .home-custom-banners .item:hover, #product-category .home-category-banners .item:hover {
  opacity: 1;
}

#product-category .home-custom-banners .item:hover img, #product-category .home-category-banners .item:hover img {
  transform: scale(1.15);
}

#product-category .home-custom-banners .item:hover .arrow, #product-category .home-category-banners .item:hover .arrow {
  width: 80px;
}

#product-category .home-category-banners .item {
  width: calc(100% / 3 - 15px);
  margin-right: 15px;
}

#product-category .home-category-banners .item .text {
  top: unset;
  bottom: 15px;
}

#product-category .home-category-banners .item h3 {
  color: var(--dark-text);
}

#product-category .home-category-banners .item .arrow {
  border-color: var(--dark-text);
  margin: 15px auto 0;
}

#product-category .home-category-banners .item .arrow:after {
  background-color: var(--dark-text);
}

#product-category .home-category-banners .item:nth-child(2) {
  width: calc(100% / 3 * 2 - 15px);
}

#product-category .home-category-banners .item:nth-child(2n + 1) {
  margin-right: 15px;
}

@media (max-width: 768px) {
  #product-category .catalog-subcategory .item-container, #product-category .catalog-top-categories .item-container {
    width: calc(100% / 4);
  }
  #product-category .catalog-subcategory .item-container:nth-child(4n + 4):after, #product-category .catalog-top-categories .item-container:nth-child(4n + 4):after {
    content: none;
  }
}

@media (max-width: 576px) {
  #product-category .catalog-subcategory .item-container, #product-category .catalog-top-categories .item-container {
    width: calc(100% / 2);
  }
  #product-category .catalog-subcategory .item-container:nth-child(2n + 2):after, #product-category .catalog-top-categories .item-container:nth-child(2n + 2):after {
    content: none;
  }
  #product-category .catalog-subcategory.first-categories .item-container, #product-category .catalog-top-categories.first-categories .item-container {
    width: calc(100% / 2);
  }
  #product-category .home-category-banners .item {
    width: 100%;
  }
  #product-category .home-category-banners .item:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }
  #product-category .home-category-banners .item:nth-child(2n + 1) {
    width: 100%;
    margin-right: 0;
  }
}

#product-product .thumbnails .thumbnail {
  border: none;
  max-width: 487px;
  background-color: var(--product-bg);
}

#product-product .instock {
  text-align: right;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.433333px;
  color: var(--dark-text);
  opacity: 0.4;
}

#product-product .instock.stocked::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--green);
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: middle;
}

#product-product h1 {
  font-size: 58px;
  line-height: 44px;
  letter-spacing: 3.48px;
  color: var(--dark-text);
  margin-bottom: 35px;
}

#product-product .subheading .btn {
  display: block;
  margin-left: auto;
  background: transparent;
  border: none;
  box-shadow: none;
}

#product-product .subheading .btn i {
  margin-right: 12px;
  display: inline-block;
}

#product-product .product-description {
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: var(--lightgrey-text);
}

#product-product .product-description p {
  font: inherit;
  color: inherit;
}

#product-product .product-description:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--grey-border);
  margin: 30px 0;
}

#product-product #product {
  padding-top: 48px;
  padding-bottom: 70px;
}

#product-product #product .option-group {
  max-width: 375px;
}

#product-product #product .option-group .control-label {
  margin-bottom: 10px;
}

#product-product #product .option-group .radio label {
  width: 100%;
}

#product-product #product .option-group .radio input {
  top: -3px;
}

#product-product #product .option-group .radio .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#product-product #product .option-group .radio .option-price {
  font-weight: bold;
}

#product-product #product .option-group .radio {
  margin: 0 12px;
}

#product-product #product .option-group .radio input[type="radio"] {
  margin: 0 0 0 -20px;
}

#product-product #product .quantity-group {
  display: flex;
  align-items: center;
}

#product-product #product .quantity-group .quantity-controls {
  user-select: none;
  margin-left: 12px;
}

#product-product #product .form-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#product-product #product .form-footer li h2 {
  margin-right: 57px;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 1.06667px;
  color: var(--dark-text);
}

@media (max-width: 576px) {
  #product-product #product .form-footer {
    display: block;
  }
  #product-product #product .form-footer #button-cart {
    margin-top: 16px;
  }
}

#information-contact .locations {
  display: flex;
  padding-bottom: 30px;
}

#information-contact .locations .location {
  width: 50%;
  flex-shrink: 0;
}

#information-contact .locations .location .heading {
  padding-left: 70px;
}

#information-contact .locations .location img {
  width: 33px;
  height: 33px;
  margin-right: 37px;
}

#information-contact .locations .location .loc-address, #information-contact .locations .location .loc-phone {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#information-contact .locations .location .location-phone > strong, #information-contact .locations .location .location-place > strong {
  padding-left: 70px;
}

#information-contact .locations .location .link-to-map {
  padding-left: 70px;
}

#information-contact .locations .location .location-address, #information-contact .locations .location .location-phone, #information-contact .locations .location .location-place {
  margin-top: 30px;
}

@media (max-width: 768px) {
  #information-contact .locations {
    display: block;
  }
  #information-contact .locations .location {
    width: 100%;
  }
}

#information-information h1 {
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 1.2px;
  color: var(--dark-text);
  margin-bottom: 50px;
}

#information-information p {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.533333px;
  color: var(--grey-ancient-text);
}

#information-information img {
  width: 100%;
}

#information-information ol {
  list-style: none;
  counter-reset: li;
}

#information-information ol li {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.533333px;
  color: var(--grey-ancient-text);
  counter-increment: li;
  margin-bottom: 1em;
}

#information-information ol li:before {
  content: counter(li);
  color: red;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

#checkout-checkout:before {
  content: '';
  background-color: var(--grey-bg);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

#checkout-checkout .panel-heading {
  background-color: var(--white);
}

#checkout-checkout #accordion legend, #checkout-checkout #accordion h2 {
  margin-bottom: 16px;
}

#checkout-checkout .panel-collapse a, #checkout-checkout .panel-collapse input[type="button"] {
  display: block;
  margin-top: 10px;
}

#checkout-checkout .panel-collapse p {
  margin: 10px 0;
}

#product-search #button-search {
  margin: 12px 0 12px;
}
