#product-product {
  .thumbnails {
    .thumbnail {
      border: none;
      max-width: 487px;
      background-color: var(--product-bg);
    }
  }
  .instock {
    text-align: right;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.433333px;
    color: var(--dark-text);
    opacity: 0.4;
    &.stocked::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: var(--green);
      border-radius: 50%;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
  h1 {
    font-size: 58px;
    line-height: 44px;
    letter-spacing: 3.48px;
    color: var(--dark-text);
    margin-bottom: 35px;
  }
  .subheading {
    .btn {
      display: block;
      margin-left: auto;
      background: transparent;
      border: none;
      box-shadow: none;
      i {
        margin-right: 12px;
        display: inline-block;
      }
    }
  }
  .product-description {
    font-weight: 300;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.5px;
    color: var(--lightgrey-text);
    p {
      font: inherit;
      color: inherit;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--grey-border);
      margin: 30px 0;
    }
  }
  #product {
    padding-top: 48px;
    padding-bottom: 70px;
    .option-group {
      max-width: 375px;
      .control-label {
        margin-bottom: 10px;
      }
      //display: flex;
      //align-items: center;
      //flex-wrap: wrap;
      > div {
        //display: flex;
        //align-items: center;
      }
      .radio {
        label {
          width: 100%;
        }
        input {
          top: -3px;
        }
        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .option-price {
          font-weight: bold;
        }
      }
      .radio {
        margin: 0 12px;
        input[type="radio"] {
          margin: 0 0 0 -20px;
        }
      }
    }
    .quantity-group {
      display: flex;
      align-items: center;
      .quantity-controls {
        user-select: none;
        margin-left: 12px;
      }
    }
    .form-footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      li {
        h2 {
          margin-right: 57px;
          font-weight: bold;
          font-size: 32px;
          line-height: 32px;
          letter-spacing: 1.06667px;
          color: var(--dark-text);
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #product-product {
    #product {
      .form-footer {
        display: block;
        #button-cart {
          margin-top: 16px;
        }
      }
    }
  }

}