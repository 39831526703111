footer {
  background-color: var(--white);
}
.footer-main {
  position: relative;
  a {
    font-size: 15px;
    text-decoration: none;
    color: var(--dark-text);
    &:hover, &:focus, &:visited {
      color: var(--dark-text);
    }
  }
  .footer-top {
    border-top: 1px solid var(--grey-border);
    .footer-top-inner {
      padding-top: 45px;
      padding-bottom: 40px;
      display: flex;
      align-items: flex-start;
      #logo {
        margin-right: 80px;
      }
      .nav-info, .nav-menu {
        margin-right: 80px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            white-space: nowrap;
            width: calc(50% - 40px);
            margin-bottom: 1em;
            &:nth-child(2n + 1) {
              margin-right: 80px;
            }
          }
        }
      }
      .nav-info {
        ul {
          li {
            min-width: max-content;
          }
        }
      }
      .footer-contacts {
        a {
          font-weight: 500;
          font-size: 17px;
          margin-bottom: 10px;
          display: inline-block;
        }
      }
      #logo, .footer-contacts {
        flex-shrink: 0;
      }
    }
  }
  .footer-bottom {
    border-top: 1px solid var(--grey-border);
    .footer-bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      .copyright p {
        font-size: 13px;
      }
      .documents a, .license a {
        font-size: 11px;
        text-transform: uppercase;
      }
      .dev {
        font-size: 13px;
        span {
          color: var(--red);
        }
      }
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 1200px) {
  .footer-main {
    .footer-top {
      .footer-top-inner {
        flex-wrap: wrap;
        .nav-info, .footer-contacts {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 992px) {

}
@media (max-width: 768px) {

}
@media (max-width: 576px) {
  .footer-main .footer-top .footer-top-inner,
  .footer-main .footer-top .footer-top-inner .nav-info ul,
  .footer-main .footer-top .footer-top-inner .nav-menu ul,
  .footer-main .footer-bottom .footer-bottom-inner {
    display: block;
    li {
      width: unset;
    }
  }
  .footer-main .footer-bottom .footer-bottom-inner > div {
    margin-top: 12px;
  }
}