.alert {
  position: fixed;
  width: 99%;
  top: 0;
  left: 0;
  z-index: 6;
}
.alert-dismissible .close {
  right: 0;
}
#cart {
  display: none;
  width: 950px;
  max-width: 100%;
  padding: 30px 44px;
  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 46px;
    text-transform: uppercase;
    color: var(--dark-text);
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      margin: 12px 0 30px;
      background-color: #E8ECEF;
    }
  }
  .cart-footer {
    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      margin: 12px 0 45px;
      background-color: #E8ECEF;
    }
  }
  .dropdown-menu {
    display: block;
    float: none;
    position: relative;
  }
  .pop-cart-list {
    display: block;
    float: none;
    position: relative;
    border: none;
    box-shadow: none;
    background: transparent;
  }
  .product-name {
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 0.566667px;
    color: var(--dark-text);
  }
  .product-total {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.533333px;
    color: var(--dark-text);
  }
  .table {
    border: none;
    & > tbody > tr > td {
      border: 0;
    }
  }
  .table-striped > tbody > tr:nth-of-type(2n+1) {
    background: transparent;
  }
  .rnd-green-btn {
    margin-left: 24px;
  }

}
.header-main {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  color: var(--dark-text);
  transition: .3s;
  a {
    color: var(--dark-text);
    transition: .3s;
  }
  &.home-top {
    background-color: transparent;
    color: var(--white);
    a {
      color: var(--white);
    }
    .header-top {
      .menu-trigger {
        &:before, &:after {
          background-color: var(--white);
        }
      }
      .header-right {
        .header-icons {
          svg {
            fill: var(--white);
          }
        }
      }
    }
  }
  &.page-scrolled {
    background-color: var(--white);
    .header-top {
      height: 90px;
      padding: 20px 85px 20px 60px;
    }
  }
  .header-top {
    transition: .2s;
    position: relative;
    z-index: 6;
    height: 150px;
    padding: 50px 85px 50px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      #logo {
        width: 112px;
        height: 51px;
        margin-right: get-vw(58px);
      }
      .header-nav {
        display: flex;
        align-items: center;
        li {
          margin-left: 36px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .menu-trigger {
      position: relative;
      width: 25px;
      height: 25px;
      cursor: pointer;
      &:before, &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--dark-text);
        transition: .3s;
        left: 0;
        top: 0;
        transform-origin: top left;
      }
      &:before {

      }
      &:after {
        transform-origin: bottom left;
        top: 8px;
        width: 14px;
        height: 1px;
      }
      &.open {
        &:before {
          transform: rotate(45deg);
          width: 36px;
        }
        &:after {
          top: 100%;
          transform: rotate(-45deg);
          width: 36px;
          height: 2px;
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .header-icons {
        margin-left: get-vw(78px);
        display: flex;
        align-items: center;
        li {
          margin-left: 27px;
        }
        svg {
          transition: .3s fill;
          width: 100%;
          height: auto;
          max-width: 16px;
          max-height: 16px;
          object-fit: cover;
          fill: var(--dark-text);
        }
        .cart-icon {
          position: relative;
          .cart-total {
            position: absolute;
            left: 50%;
            bottom: 100%;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .header-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--dark-bg);
    .menu-container {
      padding-top: 190px;
      .menu-top, .menu-bottom {
        margin: 0 auto;
        width: 1464px;
        max-width: 100%;
        padding: 0 20px;
      }
      .menu-top {
        display: flex;
        justify-content: space-between;
        .nav-menu, .nav-info {
          width: 45%;
          ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            li {
              width: 45%;
              margin-bottom: 2em;
              white-space: nowrap;
            }
          }
          a {
            font-size: 24px;
            //padding: 10px 6px 10px;
            line-height: 28px;
          }
        }
      }
    }
  }
  &.open {
    color: var(--white);
    a {
      color: var(--white);
    }
    .header-top {
      .menu-trigger {
        &:before, &:after {
          background-color: var(--white);
        }
      }
      .header-right {
        .header-icons {
          svg {
            fill: var(--white);
          }
        }
      }
    }
  }
  .search-container {
    position: relative;
    #search {
      margin: 0;
      position: absolute;
      bottom: 0;
      right: calc(100% + 8px);
      width: 300px;
      display: none;
      overflow: hidden;
      max-width: calc(100vw - 150px);
      input {
        background-color: var(--white);
        color: var(--dark-text);
        border-radius: 8px;
        width: 100%;
        padding: 4px 6px;
      }
      .input-group-btn {
        display: none;
      }
      &.active {
        width: 300px;
      }
      & + div {
        position: absolute;
        top: 40px;
        right: calc(100% + 8px);
        width: 300px;
        max-width: calc(100vw - 150px);
      }
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 1024px) {
  .header-main .header-menu .menu-container .menu-top .nav-info ul li, .header-main .header-menu .menu-container .menu-top .nav-menu ul li {
    white-space: unset;
  }
}
@media (max-width: 992px) {

}
@media (max-width: 768px) {
  .header-main {
    .header-top {
      height: 100px;
      padding: 20px;
      .header-right {
        .header-icons {
          .cart-icon {
            .cart-total {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
    &.page-scrolled {
      .header-top {
        padding: 20px;
      }
    }
    .header-menu {
      .menu-container {
        padding-top: 100px;
        height: 100%;
        overflow: auto;
        .menu-top {
          flex-direction: column;
          align-items: center;
          .nav-menu, .nav-info {
            width: 100%;
            ul {
              flex-direction: column;
              align-items: center;
              li {
                width: 100%;
                text-align: center;
              }
            }
          }
          .nav-menu {
            border-bottom: 1px solid var(--lightgrey-text);
          }
          .nav-info {
            padding-top: 15px;
          }
        }
      }
    }
    &.open {
      .header-top {
        background-color: var(--dark-bg);
      }
    }
  }
  .header-main .header-top .header-left .header-nav,
  .header-main .header-top .header-right > a {
    display: none;
  }
}
@media (max-width: 576px) {
  //*{
  //  border: 1px solid #f00 !important;
  //}
  #cart {
    .pop-cart-list {
      .table {
        tr {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
      }
    }
    .cart-footer {
      a {
        display: block;
        text-align: center;
        &:last-child {
          margin: 16px 0 0;
        }
      }
    }
  }
}