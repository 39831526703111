.latest-products-container {
  width: 1520px;
  max-width: 100%;
  margin: 0 auto;
  .section-header {
    display: flex;
    margin-bottom: 100px;
    .header {
      display: flex;
      align-items: flex-end;
      h3 {
        font-size: 53px;
        line-height: 53px;
        letter-spacing: 1.76667px;
        margin-bottom: -10px;
      }
      a {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1.22px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: var(--red);
      }
    }
    .slider-arrows {
      margin-left: auto;
      display:flex;
      .slick-arrow{
        width:57px;
        height:57px;
        border-radius:50%;
        background-color:var(--white);
        box-shadow:0 4px 12px rgba(170,170,170,.5);
        position:relative;
        transition:.3s;
        cursor:pointer;
        svg{
          width:8px;
          height:16px;
          position:absolute;
          left:calc(50% - 4px);
          top:calc(50% - 8px);
          fill:transparent;
          stroke:var(--dark-text);
          stroke-width:2px;
          transition:.3s;
        }
        &.slick-next{
          margin-left:20px;
          transform:rotateY(180deg);
        }
        &:hover{
          background-color:var(--red);
          svg{
            stroke:var(--white);
          }
        }

      }
    }
  }
  .latest-products {
    .product-item {
      padding: 0 23px;
      margin-bottom: 100px;
      //.product-item-options {
      //  display: none;
      //}
      &:hover {
        margin-bottom: 0;
      }
    }
  }
  .slider-numbers{
    margin-top:100px;
    text-align:center;
    span {
      vertical-align:baseline;
      font-weight:100;
      font-size:21px;
      line-height:21px;
      letter-spacing:1.22px;
      text-transform:uppercase;
      color:var(--dark-text);
      opacity:.5;
      &.current{
        font-size:16px;
        line-height:16px;
        letter-spacing:1.62667px;
        opacity:1;
        font-weight:500;
      }
      &.all{
        font-weight:500;
        font-size:12px;
        line-height:12px;
      }
    }
  }
}
@media (max-width: 576px) {
  .latest-products-container {
    .section-header {
      display: block;
      margin-bottom: 0;
      .slider-arrows {
        margin: 20px 0 0;
        justify-content: flex-end;
      }
    }
  }
}