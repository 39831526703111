.rnd-t-btn {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  padding: 17px 30px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.22px;
  text-transform: uppercase;
}
.rnd-dark-btn {
  border:1px solid var(--dark-text);
  background-color:var(--dark-text);
  border-radius:50px;
  padding:17px 30px 15px;
  font-weight:500;
  font-size:12px;
  line-height:12px;
  letter-spacing:1.22px;
  text-transform:uppercase;
  color:var(--white);
  transition: .2s;
  svg {
    width: 16px;
    height: 16px;
    fill: var(--white);
    margin-right: 14px;
    vertical-align: middle;
    transition: .2s;
  }
  &:hover {
    opacity: .9;
    color: var(--grey-text);
    svg {
      fill: var(--grey-text);
    }
  }
}
.rnd-green-btn {
  transition: .2s;
  border:1px solid var(--btn-green);
  background-color:var(--btn-green);
  border-radius:50px;
  padding:17px 30px 15px;
  font-weight:500;
  font-size:12px;
  line-height:12px;
  letter-spacing:1.22px;
  text-transform:uppercase;
  color:var(--white);
  svg {
    width: 16px;
    height: 16px;
    fill: var(--white);
    margin-right: 14px;
    vertical-align: middle;
    transition: .2s;
  }
  &:hover {
    opacity: .9;
    color: var(--dark-text);
    svg {
      fill: var(--dark-text);
    }
  }
}
.quantity-controls {
  display: flex;
  align-items: center;
  user-select: none;
  input.form-control  {
    border: none;
    background: none;
    box-shadow: none;
    width: 45px;
    text-align: center;
  }
  .quantity-control {
    cursor: pointer;
    width: 26px;
    height: 26px;
    color: var(--dark-text);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .2s;
    flex-shrink: 0;
    &:hover {
      background-color: var(--dark-text);
      color: var(--white);
    }
  }
}
.btn-primary {
  background-image: none;
  background-color: var(--btn-green);
  border-color: var(--btn-green);
  &:hover {
    background-color: var(--green);
  }
}