* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}
b, strong {
  font-weight: bold;
}
html, body {

}
body {
  font-family: Muller, sans-serif;
  color: var(--dark-text);
  &.ov-hidden {
    overflow: hidden;
  }
}
a {
  text-decoration: none;
  color: var(--dark-text);
  transition: .3s;
  &:hover, &:focus, &:visited {

  }
  &:hover {
    opacity: .6;
  }
}
.radio img {
  display: none;
}
p {
  color: var(--dark-text);
  font-size: 16px;
  line-height: 19px;
  margin: 10px 0;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 29, 42, 0.2);
  z-index: 9;
  &.show {
    display: block;
  }
}
#account-register {
  padding-bottom: 100px;
}
#account-login {
  #content {
    a {
      display: inline-block;
      margin-top: 10px;
    }
  }
  #column-right {
    display: none;
  }
}
fieldset {
  legend {
    margin-bottom: 16px;
  }
}
#account-account {
  h2 {
    margin: 12px 0 4px;
  }
  #content {
    li {
      margin-bottom: 2px;
    }
  }
}
.no-scroll {
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: Muller, sans-serif;
}
h1 {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--dark-text);
  margin-bottom: 30px;
}
.header-main + *:not(#common-home) {
  padding-top: 150px;
}
.breadcrumb {
  border: none;
  background-color: transparent;
  li {
    opacity: 0.5;
    padding: 0;
    &:after {
      content: '—';
      display: inline-block;
      position: relative;
      top: unset;
      right: unset;
      width: unset;
      height: unset;
      border: none;
      transform: none;
      margin: 0 10px;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
//h1 {
//  margin-bottom: 45px;
//}
//h1, .h1 {
//  font-size: 20px;
//  line-height: 19px;
//}
//.main-main {
//  padding: 80px 135px 0 0;
//}
//.page-header {
//  font-size: 20px;
//  line-height: 19px;
//  color: #FFFFFF;
//}
//.common-popup {
//  display: none;
//  max-width: 80%;
//  width: 1000px;
//  background-color: $lightBg;
//  border-radius: 10px;
//}
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
    line-height: 24px;
  }

}