#checkout-checkout {
  &:before {
    content: '';
    background-color: var(--grey-bg);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .panel-heading {
    background-color: var(--white);
  }
  #accordion {
    legend, h2 {
      margin-bottom: 16px;
    }
  }
  .panel-collapse {
    a, input[type="button"] {
      display: block;
      margin-top: 10px;
    }
    p {
      margin: 10px 0;
    }
  }
}