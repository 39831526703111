#checkout-cart {
  padding-top: 180px;
  padding-bottom: 150px;
  &:before {
    content: '';
    background-color: var(--grey-bg);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .cart-content {
    display: flex;
  }
  .cart-container, .totals-container {
    background-color: var(--white);
  }
  .cart-container {
    padding: 25px 30px;
    height: max-content;
    input {
      margin: 0;
    }
    .cart-row {
      display: flex;
      align-items: center;
      .ctm-checkbox {
        width: 14px;
        height: 14px;
        margin-right: 21px;
      }
      .product-image {
        width: 95px;
        margin-right: 32px;
      }
      .product-name {
        width: 170px;
        margin-right: 82px;
      }
      .product-price {
        width: 100px;
        text-align: right;
        margin-right: 50px;
      }
      .product-quantity {
        width: 100px;
      }
    }
    .cart-container-header {
      padding-bottom: 22px;
      border-bottom: 1px solid var(--grey-border);
      .product-image {
        .check-all {
          font-size: 13px;
          line-height: 13px;
          color: var(--dark-text);
          opacity: 0.54;
        }
      }
      .product-name {
        .delete-selected {
          font-size: 13px;
          line-height: 13px;
          letter-spacing: 0.433333px;
          color: var(--red);
        }
      }
    }
    .cart-container-body {
      .cart-row {
        margin-top: 26px;
        .product-image {
          border-radius: 2px;
          overflow: hidden;
          width: 95px;
          height: 95px;
          background-color: var(--product-bg);
          img {
            border: none;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .product-name {
          a {
            font-size: 17px;
            line-height: 17px;
            letter-spacing: 0.566667px;
            color: var(--dark-text);
          }
          small {
            font-size: 14px;
            line-height: 15px;
            color: var(--grey-text);
          }
          .cart-item-remove {
            border: 0;
            background-color: transparent;
            display: block;
            margin-top: 22px;
            color: var(--red);
            padding-left: 0;
          }
        }
      }
    }
  }
  .totals-container {
    padding: 15px 30px;
    margin-left: auto;
    height: max-content;
    position: sticky;
    top: 180px;
    &.in-progress::before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #808080a3;
      position: absolute;
    }
    h2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 46px;
      text-transform: uppercase;
      color: var(--dark-text);
    }
    .totals {
      &:before, &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--grey-border);
        margin: 30px 0;
      }
    }
    .total {
      display: flex;
      align-items: flex-start;
      color: var(--dark-text);
      > div {
        width: 50%;
        margin-bottom: 16px;
        font-size: 14px;
        &.total-value {
          font-weight: bold;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.6px;

        }
      }
      &.total-total {
        > div {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 0;
          &.total-value {
            font-size: 21px;
            line-height: 21px;
          }
        }
      }
    }
    .cart-submit {
      display: block;
      color: var(--white);
      width: 100%;
      padding: 18px;
      text-align: center;
      border-radius: 3px;
      background-color: var(--btn-green);
      &:hover {
        background-color: var(--green);
      }
    }
    .cart-submit-desc {
      margin-top: 30px;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.433333px;
      color: var(--grey-text);
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 992px) {
  #checkout-cart {
    .cart-content {
      display: block;
    }
    .totals-container {
      position: relative;
      top: 0;
    }
  }
}
@media (max-width: 768px) {

}
@media (max-width: 576px) {
  #checkout-cart {
    .cart-container {
      .cart-row {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .product-image {
          margin-right: auto;
        }
        .product-name {
          width: 100%;
          .cart-item-remove {
            margin: 10px 0;
          }
        }
      }
      .cart-container-header {
        flex-wrap: nowrap;
        align-items: center;
        .product-image {
          margin-right: 32px;
        }
        .product-name {
          width: unset;
        }
      }
    }
  }
}