.ancient-container {
  text-align: center;
  width: 780px;
  max-width: 100%;
  margin: 100px auto;
  .ancient-image {
    text-align: center;
    margin: 0 auto 47px;
    height: 35px;
    width: 35px;
  }
  h3 {
    font-weight: bold;
    font-size: 17px;
    line-height: 27px;
    letter-spacing: 1.02px;
    text-transform: uppercase;
    margin-bottom: 23px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.533333px;
    color: var(--grey-ancient-text);
  }
}
#common-home {
  .home-head {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-bottom: 100px;
    .slider-arrows{
      position:absolute;
      top:calc(50% - 67px);
      right:90px;
      z-index:1;
      .slick-arrow{
        width:57px;
        height:57px;
        border-radius:50%;
        transform-origin:center center;
        background-color:transparent;
        box-shadow:0 4px 12px rgba(170,170,170,.5);
        position:relative;
        transition:.3s;
        cursor:pointer;
        border:1px solid var(--white);
        svg{
          width:8px;
          height:16px;
          position:absolute;
          left:calc(50% - 4px);
          top:calc(50% - 8px);
          fill:transparent;
          stroke:var(--white);
          stroke-width:2px;
          transition:.3s;
        }
        &.slick-next{
          margin-top:20px;
          transform:rotateY(180deg);
        }
        &:hover{
          background-color:var(--red);
          border:1px solid var(--red);
          svg{
            stroke:var(--white);
          }
        }
      }
    }
    .home-head-slider {
      height: 100%;
      width: 100%;
      .item {
        position: relative;
        width: 100%;
        height: 100vh;
        .text {
          margin-left: get-vw(224px);
          width: 875px;
          max-width: 100%;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: var(--white);
          position: relative;
          .preheader {
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            margin-bottom: 37px;
            color: var(--white);
          }
          h2 {
            font-size: 89px;
            line-height: 89px;
            letter-spacing: 4.1px;
            margin-bottom: 64px;
            color: var(--white);
            margin-left: -6px;
          }
          .description, p, .desc * {
            font-size: 18px;
            line-height: 29px;
            letter-spacing: 1.2375px;
            max-width: 424px;
            margin-bottom: 96px;
            color: var(--white);
          }
          .rnd-t-btn {
            color: var(--white);
            width: max-content;
          }
        }
      }
      .bg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @keyframes scrollDownAnimation {
      0% {
        width: 0;
        right: 0;
      }
      50% {
        width: 100%;
        right: 0;
      }
      100% {
        right: 100%;
        width: 0;
      }
    }
    .home-head-scroll{
      position:absolute;
      bottom:0;
      left:unset;
      right:100px;
      transform-origin:left bottom;
      transform:rotate(-90deg);
      width:150px;
      text-align:right;
      color:var(--white);
      &:after{
        content:'';
        display:block;
        bottom:0;
        height:1px;
        background-color:var(--white);
        position:absolute;
        right:0;
        width:100%;
        animation:4s linear infinite scrollDownAnimation;
      }
    }
  }
  .home-middle-container{
    position:relative;
    .slider-arrows{
      position:absolute;
      top:calc(50% - 67px);
      right:90px;
      z-index:1;
      .slick-arrow{
        width:57px;
        height:57px;
        border-radius:50%;
        transform-origin:center center;
        background-color:var(--white);
        box-shadow:0 4px 12px rgba(170,170,170,.5);
        position:relative;
        transition:.3s;
        cursor:pointer;
        svg {
          width:8px;
          height:16px;
          position:absolute;
          left:calc(50% - 4px);
          top:calc(50% - 8px);
          fill:transparent;
          stroke:var(--dark-text);
          stroke-width:2px;
          transition:.3s;
        }
        &.slick-next {
          margin-top:20px;
          transform:rotateY(180deg);
        }
        &:hover{
          background-color:var(--red);
          svg{
            stroke:var(--white)
          }
        }
      }
    }
    .item{
      position:relative;
      .text {
        background-color:var(--white);
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        left:0;
        width:561px;
        max-width:60%;
        z-index:2;
        padding: 50px 90px 70px 70px;
        .preheader{
          font-size:14px;
          line-height:27px;
          letter-spacing:.466667px;
          text-transform:uppercase;
          color:var(--grey-ancient-text);
          margin-bottom:55px;
        }
        h2 {
          font-weight:400;
          font-size:58px;
          line-height:27px;
          letter-spacing:3.48px;
          color:var(--dark-text);
          margin-bottom:32px;
        }
        .description, p {
          font-weight:300;
          font-size:16px;
          line-height:27px;
          letter-spacing:.533333px;
          color:var(--grey-ancient-text);
          &:after{
            display:block;
            content:'';
            width:52px;
            height:3px;
            background-color:var(--red);
            margin:37px 0 55px;
          }
        }
      }
      .image{
        margin-left:auto;
        width:60%;
        object-fit:cover;
        z-index:0;
        img{
          width:100%;
        }
      }
    }
    .slider-numbers{
      margin-top:50px;
      text-align:center;
      span{
        vertical-align:baseline;
        font-weight:100;
        font-size:21px;
        line-height:21px;
        letter-spacing:1.22px;
        text-transform:uppercase;
        color:var(--dark-text);
        opacity:.5;
        &.current{
          font-size:16px;
          line-height:16px;
          letter-spacing:1.62667px;
          opacity:1;
          font-weight:500;
        }
        &.all{
          font-weight:500;
          font-size:12px;
          line-height:12px;
        }
      }
    }
  }
  .home-categories-container{
    width:1400px;
    max-width:100%;
    margin:100px auto;
    .item{
      position:relative;
      .text{
        position:absolute;
        z-index:1;
        width:460px;
        .preheader{
          font-size:14px;
          line-height:27px;
          letter-spacing:.466667px;
          text-transform:uppercase;
          color:var(--grey-ancient-text);
          margin-bottom:55px;
        }
        h2{
          font-weight:400;
          font-size:58px;
          line-height:58px;
          letter-spacing:3.48px;
          color:var(--dark-text);
          margin-bottom:32px;
        }
        .description{
          font-weight:300;
          font-size:16px;
          line-height:27px;
          letter-spacing:.533333px;
          color:var(--grey-ancient-text);
        }
      }
      .image img{
        width:100%;
        -o-object-fit:cover;
        object-fit:cover;
      }
      &.item-right .image{
        margin-left:auto;
      }
      &:first-child {
        .image{
          width:513px;
        }
        .text{
          top:80px;
          left:420px;
        }
      }
      &:nth-child(2) {
        .image{
          width:486px;
        }
        .text{
          bottom:60px;
          right:320px;
        }
      }
      &:nth-child(3) {
        .image{
          width:424px;
        }
        .text{
          left:50px;
          bottom:0;
          transform:translateY(50%);
        }
      }
      &:nth-child(4) {
        .image {
          width:553px;
        }
        .text {
          bottom:60px;
          right:360px;
        }
      }
      &:nth-child(5) {
        .image {
          width:445px;
        }
        .text {
          top:120px;
          left:420px;
        }
      }
    }
  }
  .home-custom-banners, .home-category-banners {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;
    width: 1320px;
    max-width: 100%;
    .item {
      display: block;
      width: calc(50% - 15px);
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: .6s;
        transform: scale(1);
      }
      .text {
        left: 50%;
        top: 90px;
        position: absolute;
        transform: translateX(-50%);
      }
      h3 {
        font-size: 13px;
        letter-spacing: 10px;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--white);
        max-width: 100%;
        width: max-content;
      }
      .arrow {
        margin: 30px auto;
        width: 40px;
        height: 10px;
        border-bottom: 2px solid #fff;
        position: relative;
        transition: .6s;
        &:after {
          content: "";
          position: absolute;
          height: 10px;
          width: 2px;
          left: calc(100% - 5px);
          transform: rotate(135deg);
          bottom: -2px;
          background-color: #fff;
        }
      }
      &:nth-child(2n + 1) {
        margin-right: 15px;
      }
      &:hover {
        opacity: 1;
        img {
          transform: scale(1.15);
        }
        .arrow {
          width: 80px;
        }
      }
    }
  }
  .home-category-banners {
    .item {
      width: calc(100% / 3 - 15px);
      margin-right: 15px;
      .text {
        top: unset;
        bottom: 15px;
      }
      h3 {
        color: var(--dark-text);
      }
      .arrow {
        border-color: var(--dark-text);
        margin: 15px auto 0;
        &:after {
          background-color: var(--dark-text);
        }
      }
      &:nth-child(2) {
        width: calc(100% / 3 * 2 - 15px);
      }
      &:nth-child(2n + 1) {
        margin-right: 15px;
      }
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 1200px) {
  #common-home {
    .home-head {
      .home-head-slider {
        .item {
          .text {
            margin-left: 6vw;
            width: calc(100% - 6vw);
            h2 {
              font-size: 32px;
              line-height: 32px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  #common-home {
    .home-categories-container {
      padding: 30px;
      .item:nth-child(n) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
        .text {
          position: static;
          order: 1;
          transform: unset;
          width: unset;
          .preheader {
            margin-bottom: 10px;
          }
          h2 {
            margin-bottom: 16px;
          }
        }
        .image {
          order: 0;
          margin-left: 0;
          max-width: 100%;
          width: 350px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #common-home {
    .home-categories-container {
      padding: 20px;
    }
  }
  .latest-products-container {
    .section-header {
      .header {
        display: block;
        h3 {
          font-size: 32px;
          line-height: 32px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #common-home {
    .home-head {
      .home-head-slider {
        .item {
          .text {
            width: calc(100% - 6vw - 67px);
          }
        }
      }
      .slider-arrows {
        right: 10px;
      }
      .home-head-scroll {
        right: -100px;
      }
    }
    .latest-products-container {
      padding: 0 20px;
    }
    .ancient-container {
      padding: 10px 20px;
    }
    .home-middle-container {
      .slider-arrows {
        top: 50%;
        right: 20px;
        z-index: 1;
        transform: translateY(-50%);
      }
      .item {
        .text {
          position: static;
          max-width: 100%;
          transform: unset;
          padding: 0 0 30px 0;
        }
        .image {
          width: 100%;
        }
      }
    }
    .home-custom-banners {
      .item {
        width: 100%;
        margin: 0 0 20px 0;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
      }
    }
    .home-category-banners {
      .item {
        width: 100%;
        &:nth-child(2) {
          width: 100%;
          margin-right: 0;
        }
        &:nth-child(2n + 1) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}