.product-item {
  position: relative;
  padding: 20px;
  transition: .35s;
  .product-thumb {
    border: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    .img-additional {
      display: none;
    }
    .caption {
      padding: 43px 0 0;
      flex-grow: 1;
      h4 a {
        font-size: 17px;
        line-height: 17px;
        letter-spacing: 0.566667px;
        font-style: normal;
        font-weight: normal;
      }
      > p {
        margin: 10px 0 14px;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.433333px;
        color: var(--grey-text);
      }
      .sku {
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.497px;
        color: var(--grey-text);
      }
      .product-item-options {
        padding-top: 12px;
        .nomat-price {
          padding-bottom: 12px;
          span {
            font-weight: bold;
          }
        }
        .form-select {
          width: 50%;
          float: left;
        }
        &:after {
          display: table;
          content: '';
          clear: both;
        }
      }
    }
  }
  .button-group {
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
    .price {
      font-weight: bold;
      font-size: 17px;
      line-height: 17px;
      letter-spacing: 0.566667px;
    }
    .cart-add-btn {
      width: 48px;
      height: 48px;
      background-color: var(--dark-text);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--white);
      }
      &:hover {
        background-color: var(--red);
      }
    }
    .wishlist-add-btn {
      opacity: 0;
      transition: .3s;
      position: absolute;
      top: 18px;
      right: 23px;
      width: auto;
      border: none;
      height: auto;
      background-color: transparent;
      &:hover {
        background-color: transparent;
        svg {
          fill: var(--grey-text);
        }
      }
      svg {
        width: 18px;
        height: 16px;
        fill: var(--grey-like);
      }
    }
  }
  .radio {
    label {
      width: 100%;
    }
    input {
      top: -3px;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .option-price {
      font-weight: bold;
    }
  }
  &:hover {
    box-shadow: 0px 13px 33px rgba(0, 0, 0, 0.0805593);
    .button-group {
      .wishlist-add-btn {
        opacity: 1;
      }
    }
  }
}
.catalog-items{
  display:flex;
  flex-wrap:wrap;
  margin-right: -30px;
  margin-left: -30px;
  .product-item {
    width:calc(25% - 60px);
    margin:33px 30px;
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 1200px) {
  .catalog-items {
    .product-item {
      width: calc(100% / 3 - 30px * 2);
    }
  }
}
@media (max-width: 992px) {
  .catalog-items {
    margin-left: 0;
    margin-right: 0;
    .product-item {
      width: calc(100% / 2 - 30px * 2);
    }
    .product-thumb {
      .caption {
        //.product-item-options {
        //  display: block;
        //}
      }
    }
  }
}
@media (max-width: 768px) {
  .catalog-items {
    .product-item {
      width: 100%;
    }
  }
}
@media (max-width: 576px) {
  .catalog-items {
    .product-item {
      width: 100%;
    }
  }
}