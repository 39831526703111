:root {
  --dark-bg : #101D2A;
  --dark-text : #01152B;
  --grey-text : #9DAFC2;
  --lightgrey-text : #4E6781;
  --red : #D22835;
  --white : #ffffff;
  --grey-border : rgba(131, 160, 191, 0.5);
  --grey-like : #E3E9F0;
  --grey-bg : #F2F2F2;
  --grey-ancient-text : #5D7692;
  --product-bg : #F7F6F4;
  --green : #76BD29;
  --btn-green : #6DB84F;
}
$darkBg : #101D2A;
$darkText : #01152B;
$greyText : #9DAFC2;
$red : #D22835;