#information-contact {
  .locations {
    display: flex;
    padding-bottom: 30px;
    .location {
      width: 50%;
      flex-shrink: 0;
      .heading {
        padding-left: 70px;
      }
      img {
        width: 33px;
        height: 33px;
        margin-right: 37px;
      }
      .loc-address, .loc-phone {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .location-phone, .location-place {
        > strong {
          padding-left: 70px;
        }
      }
      .link-to-map {
        padding-left: 70px;
      }
      .location-address, .location-phone, .location-place {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 1200px) {

}
@media (max-width: 992px) {

}
@media (max-width: 768px) {
  #information-contact {
    .locations {
      display: block;
      .location {
        width: 100%;
      }
    }
  }
}
@media (max-width: 576px) {

}