#information-information {
  h1 {
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 1.2px;
    color: var(--dark-text);
    margin-bottom: 50px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.533333px;
    color: var(--grey-ancient-text);
  }
  img {
    width: 100%;
  }
  ol {
    list-style: none;
    counter-reset: li;
    li {
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.533333px;
      color: var(--grey-ancient-text);
      counter-increment: li;
      margin-bottom: 1em;
      &:before {
        content: counter(li);
        color: red;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
@media (max-width: 1440px) {

}
@media (max-width: 992px) {

}
@media (max-width: 768px) {

}
@media (max-width: 576px) {

}